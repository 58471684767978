import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Guid } from 'guid-typescript';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
// Assets
import { useParams } from 'react-router-dom';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Custom components
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { VSeparator } from 'components/separator/Separator';
import { connect } from 'react-redux';
import BrandLogo from '../../assets/svg/DhanVitaLogo.svg';
import BrandName from '../../assets/svg/DhanVita ERP.svg';
import loginImage from '../../assets/svg/SignInImage.svg';
import instituteService from 'services/InstituteService';
import { Constants } from 'services/Constants';
import CommonToast from 'views/common/Toast';
function AdminLogin({ signIn, isAuthenticated }: any) {
  // Chakra color mode
  const textColorSecondary = 'gray.400';
  const [show, setShow] = React.useState(false);
  const [encKey, setEnckey] = React.useState<string>('')
  const handleClick = () => setShow(!show);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  let { encryptedkey } = useParams();
  useEffect(() => {
    encryptedkey = encryptedkey ? encodeURIComponent(encryptedkey) : '';
    setEnckey(encryptedkey)
    console.log("in useefffecttttttt", encryptedkey)
    const currentUser = instituteService?.getCurrentUser();
    if (currentUser) {
      navigate('/admin/settings/instituteprofile');
    }
    if (encryptedkey) {
      instituteService.onBoardInstitute(encryptedkey).then((res: any) => {
        if (res?.data?.IsLinkExpired === 1) {
          //link exp
          navigate('/expired')
        }

        else if (res.data.instituteStatusID === Constants.active_instituteStatusID) {
          //institute createe
          setEnckey('')
        }

        else if ((res.data.instituteStatusID === Constants.new_instituteStatusID || res.data.instituteMasterID) && res.data.emailID) {
          //institute onboard
          let email = res.data.emailID
          localStorage.setItem('instituteMasterID', res.data.instituteMasterID);
          localStorage.setItem('parentInstituteMasterID', res.data.instituteMasterID);
          navigate(`/createuser`, {
            state: {
              email
            }
          })
        }

        else if (res.data.instituteStatusID === Constants.new_instituteStatusID && res.data.employeeMasterID) {
          // institute employee
          setEnckey('')
          localStorage.setItem('instituteMasterID', res.data.instituteMasterID);
          localStorage.setItem('employeemasterID', res.data.employeeMasterID);
          localStorage.setItem('parentInstituteMasterID', res.data.instituteMasterID);
        }
      })
    }
  }, []);

  const validationSchema = Yup.object().shape({
    emailID: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });


  const handleLogin = async (formValues: any) => {
    setLoading(true);
    instituteService.userSignIn(formValues).then((res: any) => {
      const { emailID, firstName } = res.data || {}
      setLoading(false);
      if (res?.data.changePassword) {
        console.log("changePassword",)
        navigate(`/newuser`, {
          state: {
            emailID, firstName
          }
        })
      } else if (res.data.userID && !Guid.parse(res.data.userID).isEmpty() && res.data.parentInstituteMasterID) {
        localStorage.setItem('instituteMasterID', res.data.parentInstituteMasterID);
        localStorage.setItem('parentInstituteMasterID', res.data.parentInstituteMasterID);
        localStorage.setItem('userID', res.data.userID);
        localStorage.setItem('employeemasterID', res.data.employeeMasterID);
        localStorage.setItem('userdetails', JSON.stringify(res.data))
        instituteService.setGlobals()
        instituteService.getInstituteById(res.data.parentInstituteMasterID).then((res: any) => {
          if (res.status == 200) {
            if (res.data.instituteStatusID === Constants.new_instituteStatusID) {
              const { instituteTypeID, instituteName, contactNumber, email, societyName } = res.data || {}
              console.log("instituteTypeID", instituteTypeID)
              navigate(`/institutions`, {
                state: {
                  instituteTypeID, instituteName, contactNumber, email, societyName
                }
              })
            }
            else {
              navigate(`/admin/dashboard`);
            }
          }
        })
      } else {
        CommonToast({
          description: res.data.result,
          status: 'error',
        })
      }
    }).catch(err => {
      setLoading(false);
    });
  };

  const forgotPwd = () => {
    navigate(`/forgotpassword`);
  }
  return (
    <>
      <Flex className="logingrid">
        <div className="firsthalf">
          <Flex
            justifyContent={'flex-start'}
            alignItems={'center'}
            padding={'32px 0px 0px 32px'}
          >
            <img
              src={BrandLogo}
              style={{
                width: '70px',
                height: '70px',
                padding: '8px',
                marginLeft: '10px',
                marginRight: '10px',
              }}
            />
            <img src={BrandName} style={{ height: '20px' }} />
          </Flex>
          <Box>
            {!encKey ? <Text
              color={'#FFF'}
              marginTop={'95px'}
              fontSize={'32px'}
              fontWeight={600}
              textAlign={'center'}
            >
              Login
            </Text> : <Text
              color={'#FFF'}
              marginTop={'95px'}
              fontSize={'32px'}
              fontWeight={600}
              textAlign={'center'}
            >
              Please Wait
            </Text>}

            <Flex
              zIndex="2"
              direction="column"
              w={{ base: '100%', md: '403px' }}
              maxW="100%"
              justifyContent={'center'}
              margin={'auto'}
            >
              <Formik
                initialValues={{
                  emailID: '',
                  password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                {!encKey && <Form>
                  <FormLabel className="formLabel">Email*</FormLabel>
                  <Field name="emailID">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.emailID && form.touched.emailID
                        }
                      >
                        <Input
                          // isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          type="email"
                          placeholder="Email"
                          fontWeight="bold"
                          size="lg"
                          background={'#FFF'}
                          borderRadius={'5px'}
                          {...field}
                        />
                        <FormErrorMessage>
                          {form.errors.emailID}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <VSeparator marginTop="20px" />
                  <FormLabel className="formLabel" marginTop={'24px'}>
                    Password*
                  </FormLabel>
                  <Field name="password" display="flex">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <InputGroup size="md">
                          <Input
                            // isRequired={true}
                            fontSize="sm"
                            placeholder="Password"
                            size="lg"
                            type={show ? 'text' : 'password'}
                            variant="auth"
                            {...field}
                            background={'#FFF'}
                            borderRadius={'5px'}
                          />
                          <InputRightElement
                            display="flex"
                            alignItems="center"
                            mt="4px"
                          >
                            <Icon
                              color={textColorSecondary}
                              _hover={{ cursor: 'pointer' }}
                              as={show ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                              onClick={handleClick}
                              mr={3}
                            />
                          </InputRightElement>
                        </InputGroup>
                        <Text
                          fontSize={'12px'}
                          fontWeight={400}
                          paddingTop={'12px'}
                          float={'right'}
                          color={'#FFF'}
                          onClick={() => forgotPwd()}
                          cursor="pointer"

                        // style={{ display: encryptedkey ? 'block' : 'none' }}
                        >
                          Forgot Password
                        </Text>
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <VSeparator marginTop="75px" />
                  <Button
                    fontSize="sm"
                    background={'#FFE663'}
                    variant="solid"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="24px"
                    type="submit"
                    isLoading={loading}
                    loadingText="Signing"
                    borderRadius={'5px'}
                  >
                    Login
                  </Button>
                </Form>}

              </Formik>
            </Flex>
          </Box>
        </div>

        <div className="secondhalf">
          <img className="secondhalfimage" src={loginImage} alt="" />
        </div>
      </Flex>
    </>
  );
}
const mapStateToProps = (state: any) => ({
});
export default connect(mapStateToProps, {})(AdminLogin);
