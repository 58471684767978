import { InputProps } from 'components/dynamicform';
import { Constants } from 'services/Constants';
export const InstitutionFormData = (dropDownsetData: any, isReadonly: boolean, showSocietyName: boolean, checkboxReadonly?: boolean) => {

  const accept10Numbers = (e: React.KeyboardEvent<HTMLElement>) => {
    const digitRegex = /^\d$/;
    const input = e.target as HTMLInputElement;
    if (!digitRegex.test(e.key)) {
      e.preventDefault();
    }
    else if (input.value.length >= 10) {
      e.preventDefault();
    }
  }
  const accept6Numbers = (e: React.KeyboardEvent<HTMLElement>) => {
    const digitRegex = /^\d$/;
    const input = e.target as HTMLInputElement;
    if (!digitRegex.test(e.key)) {
      e.preventDefault();
    }
    else if (input.value.length >= 6) {
      e.preventDefault();
    }
  }
  const acceptNumbers = (evt: KeyboardEvent) => {
    if (!/^[0-9]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };
  const acceptCharacters = (evt: KeyboardEvent) => {
    if (!/^[a-zA-Z\s]{1}[ a-zA-Z\s]*$/.test(evt.key)) {
      evt.preventDefault();
    }
  };
  const AffiliatedOptions =
    dropDownsetData &&
    dropDownsetData?.AffiliatedTo &&
    dropDownsetData?.AffiliatedTo?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));
  const curriculumOptions =
    dropDownsetData &&
    dropDownsetData?.Curriculum &&
    dropDownsetData?.Curriculum?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));
  const instituteTypeOptions =
    dropDownsetData &&
    dropDownsetData?.InstituteType &&
    dropDownsetData?.InstituteType?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));
  const buildingTypeOptions =
    dropDownsetData &&
    dropDownsetData?.BuildingType &&
    dropDownsetData?.BuildingType?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));
  const schoolOwnerShipDetailsOptions =
    dropDownsetData &&
    dropDownsetData?.SchoolOwnerShipDetails &&
    dropDownsetData?.SchoolOwnerShipDetails?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));
  const accreditationOptions =
    dropDownsetData &&
    dropDownsetData?.Accreditation &&
    dropDownsetData?.Accreditation?.map((item: any) => ({
      value: item?.dropdownSetID,
      desc: item?.dropdownValue,
    }));

  const handleAffiliated = (event: any, formikFn: Function) => {
    const selectedValue = event?.target?.value;
    formikFn(event?.target?.name, selectedValue);
    let selectedOpt =
      event?.target?.selectedOptions?.[0]?.innerText?.toLowerCase();
    const university = InstitutionFormData?.InstitutionForm?.find(
      (item: any) => item?.name?.toLowerCase() === 'universityname',
    );
    const index = InstitutionFormData?.InstitutionForm?.findIndex(
      (x) => x?.name?.toLowerCase() == 'universityname',
    );
    if (selectedOpt !== 'university') {
      if (university) {
        InstitutionFormData?.InstitutionForm?.splice(index, 1);
      }
    } else {
      if (!university) {
        InstitutionFormData?.InstitutionForm?.splice(3, 0, {
          type: 'text',
          name: 'universityName',
          section: 'institute',
          label: 'University ( Not for Schools & Jr.Colleges )',
          value: '',
          validations: [],
        });
      }
    }
  };


  const InstitutionFormData: { [s: string]: InputProps[] } = {
    InstitutionForm: [
      {
        type: 'text',
        name: 'instituteName',
        label: 'Institute Name*',
        section: 'institutes',
        value: '',
        keyPress: acceptCharacters,
        validations: [
          {
            type: 'required',
            message: 'Institute Name is required',
          },
          {
            type: 'maxLength',
            value: 50,
            message: 'Institute Name should not exceed 50 characters',
          },
          {
            type: 'minLength',
            message: 'Institute Name should be more than 10 characters',
            value: 10,
          },
        ],
      },
      {
        type: 'text',
        name: 'location',
        label: 'Location*',
        section: 'institute',
        value: '',
        keyPress: acceptCharacters,
        validations: [
          {
            type: 'required',
            message: 'Location is required',
          },
          {
            type: 'maxLength',
            value: 50,
            message: 'Location should not exceed 50 characters',
          },
          {
            type: 'minLength',
            message: 'Location should be more than 2 characters',
            value: 2,
          },
        ],
      },
      {
        type: 'select',
        name: 'instituteTypeID',
        section: 'institute',
        label: 'Institute Type*',
        value: '',
        options: instituteTypeOptions,
        validations: [
          {
            type: 'required',
            message: 'Institute Type is required',
          },
        ],
      },
      {
        type: 'text',
        name: 'instituteCode',
        label: 'Institute/U-DISE Code*',
        section: 'institute',
        value: '',
        validations: [
          {
            type: 'required',
            message: 'Institute/U-DISE Code is required',
          },
          {
            type: 'maxLength',
            value: 11,
            message: 'Institute/U-DISE Code not exceed 11 characters',
          },
          {
            type: 'minLength',
            message: 'Institute/U-DISE Code should be more than 3 characters',
            value: 3,
          },
        ],
      },
      {
        type: 'select',
        name: 'schoolOwnerShipDetailID',
        section: 'institute',
        label: 'School Ownership Detail*',
        value: '',
        options: schoolOwnerShipDetailsOptions,
        validations: [
          {
            type: 'required',
            message: 'School Ownership Detail is required',
          },
        ],
      },

      {
        type: 'select',
        name: 'buildingTypeID',
        section: 'institute',
        label: 'Building Type*',
        value: '',
        options: buildingTypeOptions,
        validations: [
          {
            type: 'required',
            message: 'Building Type is required',
          },
        ],
      },


      {
        type: 'select',
        name: 'affiliatedToID',
        section: 'institute',
        label: 'Affiliated To*',
        onChange: handleAffiliated,
        value: '',
        options: AffiliatedOptions,
        validations: [
          {
            type: 'required',
            message: 'Affiliated To is required',
          },
        ],
      },
      {
        type: 'checkboxgroup',
        name: 'affiliationDetailIDs',
        label: 'Affiliation DetailIs ',
        value: '',
        options: AffiliatedOptions,
        typeValue: 'array',
        validations: [],
      },
      // multiple checkboxes id's if affliationto is multiple

      {
        type: 'text',
        name: 'universityName',
        section: 'institute',
        label: 'University ( Not for Schools & Jr.Colleges )',
        value: '',
        validations: [],
      },

      {
        type: 'select',
        name: 'accreditationID',
        section: 'institute',
        label: 'Accreditation*',
        value: '',
        options: accreditationOptions,
        validations: [
          {
            type: 'required',
            message: 'Accreditation is required',
          },
        ],
      },


      {
        type: 'select',
        name: 'curriculumID',
        label: 'Curriculum',
        section: 'institute',
        placeholder: 'Curriculum',
        value: 0,
        options: curriculumOptions,
        validations: [

        ],
      },

      {
        type: 'text',
        name: 'promoterName',
        label: 'Promoter Name*',
        section: 'institute',
        placeholder: 'Promoter Name',
        value: '',
        keyPress: acceptCharacters,
        validations: [
          {
            type: 'required',
            message: 'Promoter Name is required',
          },
          {
            type: 'isAlphabeticspaceallowed',
            message: 'Promoter Name must only contain characters',
          },
          {
            type: 'maxLength',
            value: 50,
            message: 'Promoter Name should not exceed 50 characters',
          },
          {
            type: 'minLength',
            message: 'Promoter Name should be more than 3 characters',
            value: 3,
          },
        ],
      },
      {
        type: 'text',
        name: 'promoterContactNumber',
        section: 'institute',
        label: 'Promoter Contact Number*',
        value: '',
        keyPress: accept10Numbers,
        validations: [
          {
            type: 'required',
            message: 'Promoter Contact Number is required',
          },
          {
            type: 'maxLength',
            message: 'Promoter Contact Number should not exceed 10 digits',
            value: 10,
          },
          {
            type: 'minLength',
            message: 'Promoter Contact Number should have 10 digits',
            value: 10,
          },
          {
            type: 'isContact',
            message: 'Promoter Contact Number is not valid',
          },
        ],
      },
      {
        type: 'text',
        name: 'contactNumber',
        section: 'institute',
        label: 'Institute Contact Number*',
        value: '',
        keyPress: accept10Numbers,
        validations: [
          {
            type: 'required',
            message: 'Institute Contact Number is required',
          },
          {
            type: 'maxLength',
            message: 'Institute Contact Number should not exceed 10 digits',
            value: 10,
          },
          {
            type: 'minLength',
            message: 'Institute Contact Number should have 10 digits',
            value: 10,
          },
          {
            type: 'isContact',
            message: 'Institute Contact Number is not valid',
          },
        ],
      },
      {
        type: 'text',
        name: 'email',
        section: 'institute',
        label: 'Institute Email*',
        value: '',
        readOnly: isReadonly ? true : false,
        validations: [
          {
            type: 'required',
            message: 'Email is required',
          },
          {
            type: 'customEmail',
            message: 'Enter Valid Email',
          },
        ],
      },
      {
        type: 'text',
        name: 'website',
        label: 'Website',
        section: 'website',
        value: '',
        validations: [

        ],
      },
      {
        type: 'text',
        name: 'yearOfEstablishment',
        section: 'institute',
        label: 'Year of Establishment*',
        value: '',
        keyPress: acceptNumbers,
        validations: [
          {
            type: 'required',
            message: 'Year of Establishment is required',
          },
          {
            type: 'maxLength',
            value: 4,
            message: 'Year of Establishment should not exceed 4 digits',
          },
          {
            type: 'minLength',
            value: 4,
            message: 'Year of Establishment should have 4 digits',
          },
        ],
      },
      {
        type: 'text',
        name: 'studentStrengthPY',
        section: 'institute',
        label: 'Student Strength Previous Year*',
        value: '',
        keyPress: acceptNumbers,
        validations: [
          {
            type: 'required',
            message: 'Student Strength Previous Year is required',
          },
          {
            type: 'maxLength',
            value: 6,
            message: 'Student Strength Previous Year should not exceed 6 digits',
          },
          {
            type: 'minLength',
            value: 3,
            message: 'Student Strength Previous Year should have 3 digits',
          },
        ],
      },
      {
        type: 'text',
        name: 'studentStrengthCY',
        section: 'institute',
        label: 'Student Strength Current Year*',
        value: '',
        keyPress: acceptNumbers,
        validations: [
          {
            type: 'required',
            message: 'Student Strength Current Year is required',
          },
          {
            type: 'maxLength',
            value: 6,
            message: 'Student Strength Current Year should not exceed 6 digits',
          },
          {
            type: 'minLength',
            value: 3,
            message: 'Student Strength Current Year should have 3 digits',
          },
        ],
      },
      //address

      // {
      //   type: 'text',
      //   name: 'addressLine1',
      //   label: 'Address Line-1*',
      //   section: 'address',
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'Address Line 1 is required',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'Address Line 1 should not exceed 100 characters',
      //       value: 100,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'Address Line 1 should be more than 5 characters',
      //       value: 5,
      //     },
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'addressLine2',
      //   label: 'Address Line-2*',
      //   section: 'address',
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'Address Line 2 is required',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'Address Line 2 should not exceed 100 characters',
      //       value: 100,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'Address Line 2 should be more than 5 characters',
      //       value: 5,
      //     }
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'state',
      //   label: 'State*',
      //   section: 'state',
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'State is required',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'State should not exceed 100 characters',
      //       value: 100,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'State should be more than 3 characters',
      //       value: 3,
      //     }
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'city',
      //   label: 'City*',
      //   section: 'state',
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'City is required',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'City should not exceed 50 characters',
      //       value: 50,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'City should be more than 3 characters',
      //       value: 3,
      //     }
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'district',
      //   label: 'District*',
      //   section: 'state',
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'District is required',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'District should not exceed 50 characters',
      //       value: 50,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'District should be more than 3 characters',
      //       value: 3,
      //     }
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'pincode',
      //   label: 'Pincode*',
      //   section: 'pincode',
      //   placeholder: 'Pincode',
      //   value: '',
      //   keyPress: accept6Numbers,
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'Pincode is required',
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'Pincode should have 6 digits',
      //       value: 6,
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'Pincode should not exceed 6 digits',
      //       value: 6,
      //     },
      //   ],
      // },
      // {
      //   type: 'text',
      //   name: 'landMark',
      //   section: 'pincode',
      //   label: 'Landmark*',
      //   keyPress: acceptCharacters,
      //   value: '',
      //   validations: [
      //     {
      //       type: 'required',
      //       message: 'Landmark is required',
      //     },
      //     {
      //       type: 'isAlphabeticspaceallowed',
      //       message: 'Landmark must only contain characters',
      //     },
      //     {
      //       type: 'maxLength',
      //       message: 'Landmark should not exceed 50 characters',
      //       value: 50,
      //     },
      //     {
      //       type: 'minLength',
      //       message: 'Landmark should be more than 3 characters',
      //       value: 3,
      //     }
      //   ],
      // },


      {
        type: 'text',
        name: 'linkedInURL',
        label: 'LinkedIn URL',
        section: 'socialmedia',
        value: '',
        validations: [],
      },
      {
        type: 'text',
        name: 'facebookURL',
        section: 'socialmedia',
        label: 'Facebook URL',
        value: '',
        validations: [],
      },

      {
        type: 'text',
        name: 'twitterURL',
        section: 'socialmedia',
        label: 'Twitter URL',
        value: '',
        validations: [],
      },

      {
        type: 'text',
        name: 'instagramURL',
        section: 'socialmedia',
        label: 'Instagram URL',
        value: '',
        validations: [],
      },

      {
        type: 'file',
        name: 'societyTrustCopy',
        value: '',
        className: 'hidden',
        id: Constants.SocietyTrustRegistartionCopy,
        section: 'fileUpload',
        label: 'Society/Trust Registration Copy*',
        validations: [
          {
            type: 'required',
            message: 'Society/Trust Registration Copy is required',
          },
        ],
      },
      {
        type: 'file',
        name: 'trustByLaws',
        value: '',
        id: Constants.SocietyTrustBylaws,
        className: 'hidden',
        section: 'fileUpload',
        label: 'Society/Trust Bylaws*',
        validations: [
          {
            type: 'required',
            message: 'Society/Trust Bylaws is required',
          },
        ],
      },
      {
        type: 'file',
        name: 'trustMembersList',
        section: 'fileUpload',
        value: '',
        id: Constants.SocietyTrustMembersList,
        className: 'hidden',
        label: 'Society/Trust Members list*',
        validations: [
          {
            type: 'required',
            message: 'Society/Trust Members list is required',
          },
        ],
      },
      {
        type: 'file',
        name: 'instituteaffiliationDetails',
        value: '',
        id: Constants.AffiliationDetails,
        className: 'hidden',
        section: 'fileUpload',
        label: 'Affiliation Certificate*',
        validations: [
          {
            type: 'required',
            message: 'Affiliation Certificate is required',
          },
        ],
      },
    ],
  };
  if (showSocietyName) {
    InstitutionFormData.InstitutionForm.unshift({
      type: 'text',
      name: 'societyName',
      label: 'Society Name*',
      section: 'institute',
      value: '',
      keyPress: acceptCharacters,
      validations: [
        {
          type: 'required',
          message: 'Society Name is required',
        },
        {
          type: 'maxLength',
          value: 50,
          message: 'Society Name should not exceed 50 characters',
        },
        {
          type: 'minLength',
          message: 'Society Name should be more than 2 characters',
          value: 2,
        },
      ],
    },);
  }

  if (isReadonly) { // to show mainbranch check box in edit mode
    InstitutionFormData.InstitutionForm.unshift({
      type: 'checkbox',
      name: 'isMainBranch',
      typeValue: 'boolean',
      label: 'Is Main Branch',
      value: false,
      readOnly: checkboxReadonly,
      section: 'isMainBranch',
      validations: [
      ],

    },);
  }

  return InstitutionFormData;
};
